import { ProductsValue } from './products.value';
import { BaseParams, BaseParamsInterface } from '@app/store/models';

interface ParamsType extends BaseParamsInterface {
    catalogue_sub_code_filter?: string;
    item_new_in?: string;
    item_promo?: string;
    search?: string;
}

class ProductsValueDto extends BaseParams {
    private _params: ParamsType;

    constructor(dto: ProductsValue) {
        super(dto);

        this._params = { ...this.baseParams };

        if (dto.category) {
            this._params['catalogue_sub_code_filter'] = dto.category;
        }

        if (dto.customCategory) {
            this._params['catalogue_sub_code_filter'] = dto.customCategory;
        }

        if (dto.discount === 'novelties') {
            this._params['item_new_in'] = 'true';
        }

        if (dto.discount === 'promotions') {
            this._params['item_promo'] = 'true';
        }

        if (dto.search) {
            this._params['search'] = dto.search;
        }
    }

    get params(): Record<string, string> {
        return Object.fromEntries(Object.entries(this._params).filter(([, value]) => value !== undefined && value !== null));
    }
}

export { ProductsValueDto };
