import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectLoading, selectProducts, selectTotalItems } from '../selectors/products.selector';
import { filter } from 'rxjs';
import { Product } from '@app/store/models';
import { productsActions } from '../actions/products.actions';
import { ProductsValue } from '../models';

@Injectable({
    providedIn: 'root',
})
export class ProductsFacade {
    private readonly store = inject(Store);

    totalItems$ = this.store.select(selectTotalItems).pipe(filter((data): data is number => data !== null));
    products$ = this.store.select(selectProducts).pipe(filter((data): data is Product[] => data !== null));
    loading$ = this.store.select(selectLoading);

    getProducts(params: ProductsValue): void {
        this.store.dispatch(productsActions.getProducts({ params }));
    }

    clear(): void {
        this.store.dispatch(productsActions.clear());
    }
}
