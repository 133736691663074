import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ApiResponse } from '@app/shared/models';
import { map } from 'rxjs';
import { Product, ProductDto } from '@app/store/models';
import { ProductDescriptionDto } from '../models/product-description.dto';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private httpClient = inject(HttpClient);

    getProductDetails(id: string) {
        return this.httpClient
            .addAllSwitches()
            .get<ApiResponse<ProductDto>>(`api/v1/items/${id}`)
            .pipe(map((response) => new Product(response.data)));
    }

    getProductDescription(productNo: string) {
        return this.httpClient
            .addAllSwitches()
            .get<ApiResponse<ProductDescriptionDto>>(`api/v1/items/marketing/text/${productNo}`)
            .pipe(map((response) => response.data.description));
    }

    getProductCard(productNo: string) {
        return this.httpClient.addAllSwitches().get(`api/v1/cards/items/${productNo}`, { responseType: 'blob' });
    }
}
